import React from "react";

import "./style.css";

const SocialButton = ({ icon, title, subTitle }) => {
  return (
    <div className="button-wrapper">
      <img className="social-icon" src={icon} alt="icon" />
      <div>
        <div className="button-title">{title}</div>
        <div className="sub-title">{subTitle}</div>
      </div>
    </div>
  );
};

export default SocialButton;
