import React from "react";

import "../styles/Home.css";
import { SocialButton } from "../components";
import appleIcon from "../assets/svgs/apple-icon.svg";
import googleplayIcon from "../assets/svgs/googleplay-icon.svg";

import chinatownIcon from "../assets/svgs/chinatown-icon.svg";
import chinatownItems from "../assets/svgs/chinatown-items.svg";

const Home = () => {
  return (
    <div className="backgroundImage">
      <div className="content">
        <div className="icon-container">
          <img className="chinatown-icon" src={chinatownIcon} alt="icon" />
          <div className="title">ChinatownRPG</div>
        </div>
        <div className="scale-wrapper">
          <div className="heading">
            One Chinatown
            <br /> at a time
          </div>
          <img className="chinatown-items" src={chinatownItems} alt="icon" />
          <div className="paragraph">
            Dumplings, fried rice, massage, reishi mushroom, fishy smell,
            lantern, underground mahjong, cigarette
          </div>
          <div className="buttons-container">
            <SocialButton
              icon={appleIcon}
              title="Download on the"
              subTitle="App Store"
            />
            <SocialButton
              icon={googleplayIcon}
              title="GET IT ON"
              subTitle="Google Play"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
